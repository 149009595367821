// extracted by mini-css-extract-plugin
export var card = "StripCard-module--card--eb4d8";
export var chevron = "StripCard-module--chevron--f085f";
export var constrict = "StripCard-module--constrict--c7a11";
export var content = "StripCard-module--content--4b303";
export var icon = "StripCard-module--icon--5f334";
export var label = "StripCard-module--label--71254";
export var link = "StripCard-module--link--45e9c";
export var rightBounce = "StripCard-module--right-bounce--42952";
export var thumbnail = "StripCard-module--thumbnail--c143d";
export var thumbnailBounds = "StripCard-module--thumbnail-bounds--fabf5";